
import { AvButton, AvExportButton } from "@/components";
import { AvSkeletonTable } from "@/components/av-skeleton";
// Services
import { useArea, useRegion } from "@/services";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  IResponseDeleteArea,
  IResponseListArea,
} from "@core/services/interfaces/covarege-area/area/IAreaService";
import { IResponseListRegion } from "@core/services/interfaces/covarege-area/region/IRegionService";
import { IOption } from "@core/services/interfaces/IUtil";
import useTable from "@core/utils/useTable";
import { AxiosResponse } from "axios";
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BvTableFieldArray,
} from "bootstrap-vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { RouterLink } from "vue-router";
import vSelect from "vue-select";

interface IAreaTable {
  id: number;
  name: string;
  code: number;
  region: string;
}

@Component({
  name: "AreaList",
  components: {
    //Components Bootstrap
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BTable,
    AvButton,
    AvExportButton,
    AvSkeletonTable,
    BPagination,
    BFormGroup,
    BFormInput,
    RouterLink,
    vSelect,
  },
})
export default class AreaList extends Vue {
  // Data
  regionFilter: number | null = null;
  regionOptions: IOption[] = [];
  tableColumns: BvTableFieldArray = [
    { key: "name", label: "Nome", sortable: true },
    { key: "code", label: "Código", sortable: true },
    { key: "region", label: "Região Op.", sortable: true },
    { key: "actions", label: "Ações" },
  ];
  useTable = new useTable(this.tableColumns, {
    sortBy: "name",
  });
  $refs = {
    refListTable: {} as any,
  };

  // LifeCicly
  created() {
    this.fetchRegions();
    this.refreshData();
  }

  // Methods
  reduceValue(val: { label: string; value: any }) {
    return val.value;
  }

  handlerArea = (id: number | null | undefined): void => {
    if (!id) {
      this.$router.push({ name: "admin-panel-area-add" });
    } else {
      this.$router.push({
        name: "admin-panel-area-edit",
        params: { id: "" + id },
      });
    }
  };

  onFiltered(filteredItems: Array<any>) {
    this.useTable.display.value = filteredItems.length;
    this.useTable.currentPage.value = 1;
  }

  fetchRegions() {
    useRegion
      .requestList({ draw: 1, length: 100000, paginate: false })
      .then((response: AxiosResponse<IResponseListRegion>) => {
        this.regionOptions = response.data.Data.data.map((region) => ({
          label: region.Nome,
          value: region.Id,
        }));
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar filtros de regiões operacionais!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {});
  }

  @Watch("regionFilter")
  refreshData(): void {
    let userdata = undefined;
    if (this.regionFilter) {
      userdata = {
        regiaoOperacionalId: this.regionFilter,
      };
    }

    useArea
      .requestList({ paginate: false, draw: 1, length: 10000, userdata })
      .then((response: IResponseListArea) => {
        const data = response.data.Data;
        this.useTable.listTable.value = data.data.map((area) => ({
          id: area.Id,
          name: area.Nome,
          code: area.Codigo,
          region: area.RegiaoOperacionalNome,
        }));

        this.useTable.total.value = data.recordsTotal;
        this.useTable.display.value = data.recordsFiltered;
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar áreas de influência!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.useTable.firstRequest.value = false;
      });
  }

  deleteArea(id: number | undefined): void {
    this.$bvModal
      .msgBoxConfirm("Deseja apagar essa Área Operacional?", {
        title: "Por favor confirme",
        size: "sm",
        okVariant: "danger",
        okTitle: "Sim",
        cancelTitle: "Não",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then((confirmed: boolean) => {
        if (confirmed) {
          this.$store
            .dispatch("admin-area/fetchDeleteArea", id)
            .then((response: IResponseDeleteArea) => {
              const index = this.useTable.listTable.value.findIndex(
                (area: any) => area.id === id
              );
              this.useTable.listTable.value.splice(index, 1);

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Área Operacional removida com sucesso!",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title:
                    "Erro ao remover área de influência, por favor, verifique se a área não possui municípios vinculados e tente novamente",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
  }

  getAreas() {
    return new Promise((resolve) => {
      const data = this.$refs.refListTable.filteredItems.map(
        (area: IAreaTable) => ({
          Nome: area.name,
          Código: area.code,
          "Região Operacional": area.region,
        })
      );

      resolve(data);
    });
  }
}
